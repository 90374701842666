import {
  Button,
  Checkbox,
  CheckboxProps,
  Confirm,
  Dropdown,
  DropdownProps,
  Form,
  Icon,
  Input,
  InputOnChangeData,
  Label,
  Modal,
  Table,
  TextArea,
  TextAreaProps,
  Transition,
} from 'semantic-ui-react'
import React, { useEffect, useRef, useState } from 'react'
import { component as Refresh, selectors as refreshSelectors } from '../../refresh'
import {
  debounce,
  getDropDownInfo,
  getUserRoles,
  getValuesProductsInfo,
  objHasKey,
  regUserExists,
  renderLabel,
} from './helpers'
import { actions as orderUpdateActions, selectors as orderUpdateSelectors } from '../../order'
// eslint-disable-next-line import/extensions,import/no-unresolved
import { useAppDispatch, useAppSelector } from 'AppSrc/store'

import OrderNotes from '../ordernotes'
import OrderRefunds from '../orderrefunds'
import dayjs from 'dayjs'
import debug from 'debug'
// eslint-disable-next-line import/extensions,import/no-unresolved
import { userRoleEnum } from 'ServerSrc/enums'
import { objectToArray } from 'AppSrc/refresh/helpers'

debug.enable('registrants/RegistrantEditCardOrderTable:*')
// const log = debug('registrants/RegistrantEditCardOrderTable:log')
const info = debug('registrants/RegistrantEditCardOrderTable:info')
// const error = debug('registrants/RegistrantEditCardOrderTable:error')

type Props = {
  create: boolean
  reg: RegistrantType
  name: string
  values: { [id: string]: Array<string> }
  defaultValues: { [id: string]: Array<string> }
  orderIds: Array<string>
  userRoles: Array<checkin.userRoleEnum>
  onOrderTableChange: ({ ...arg }: OrderTableChangeType) => void
  orderDiscountInput: { [id: string]: number }
  setOrderDiscountInput: (orderDiscount: { [id: string]: number }) => void
}

const RegistrantEditCardOrderTable = ({
  create,
  reg,
  name,
  values,
  defaultValues,
  orderIds,
  userRoles,
  onOrderTableChange,
  orderDiscountInput,
  setOrderDiscountInput,
}: Props) => {
  const dispatch = useAppDispatch()

  const items = useAppSelector(state => refreshSelectors.items(state))
  const allItems = useAppSelector(state => refreshSelectors.allItems(state))
  const settings = useAppSelector(state => refreshSelectors.settings(state))
  const orderUpdateStatus = useAppSelector(state => orderUpdateSelectors.orderUpdateStatus(state))
  const users = useAppSelector(state => refreshSelectors.users(state))

  const [cancelOrderModalOpen, setCancelOrderModalOpen] = useState<{ [id: string]: boolean }>({})
  const [cancelOrderConfirmOpen, setCancelOrderConfirmOpen] = useState<{ [id: string]: boolean }>(
    {}
  )
  const [refundOrderModalOpen, setRefundOrderModalOpen] = useState<{ [id: string]: boolean }>({})
  const [refundOrderConfirmOpen, setRefundOrderConfirmOpen] = useState<{ [id: string]: boolean }>(
    {}
  )
  const [refundAmount, setRefundAmount] = useState<{ [id: string]: string }>({})
  const [refundReason, setRefundReason] = useState<{ [id: string]: string }>({})
  const [orderNoteModalOpen, setOrderNoteModalOpen] = useState<{ [id: string]: boolean }>({})
  const [orderNoteContent, setOrderNoteContent] = useState<{ [id: string]: string }>({})
  const [orderNotePublic, setOrderNotePublic] = useState<{ [id: string]: boolean }>({})
  const [sendStoreCredit, setSendStoreCredit] = useState<Record<string, boolean | undefined>>({})
  const [storeCreditAmount, setStoreCreditAmount] = useState<Record<string, string>>({})
  const [storeCreditMessage, setStoreCreditMessage] = useState<Record<string, string | undefined>>(
    {}
  )

  const defaultMessage = 'Hi,\n\n'
    .concat('Here is the store credit for your (now cancelled) registration. ')
    .concat('You can use it towards purchases on our website. It is valid for 6 months.')
    .concat('\n\nThe Staff')

  const userRoleEnumText = {
    default: 'default',
    '24__under': '24 & under',
    senior: 'senior',
  } as Record<checkin.userRoleEnum, string>

  const discountOptions = (Object.keys(userRoleEnum) as checkin.userRoleEnum[]).reduce(
    (acc, val) => {
      acc.push({ key: val, text: userRoleEnumText[val], value: val })
      return acc
    },
    [] as Array<{
      key: checkin.userRoleEnum
      text: string
      value: checkin.userRoleEnum
    }>
  )

  const onChange = (data: DropdownProps, orderId: string) => {
    const localValues = JSON.parse(JSON.stringify(values)) // deep copy
    if (data.value) localValues[orderId] = (data.value as Array<string>).sort()

    const { orderValues } = getValuesProductsInfo(localValues[orderId], allItems)
    localValues[orderId] = orderValues

    // log('onChange', localValues)
    onOrderTableChange({ values: localValues, userRoles })
  }

  const onChangeOrderDiscountInput =
    (orderId: string) => (evt: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
      const discount = data.value
      const newOrderDiscount = { ...orderDiscountInput }
      if (discount !== '' && !isNaN(Number(discount)) && Number(discount) >= 0) {
        newOrderDiscount[orderId] = Number(discount)
      } else {
        delete newOrderDiscount[orderId]
        // log(newOrderDiscount)
      }

      setOrderDiscountInput(newOrderDiscount)
    }

  useEffect(() => {
    debounce(() => {
      if (Object.keys(orderDiscountInput).length > 0) {
        onOrderTableChange({ orderDiscount: orderDiscountInput })
      }
    }, 200)()
  }, [orderDiscountInput])

  const modalStateChange = (key: string, orderId: string, value: boolean) => {
    const obj = {} as Record<string, boolean> // { ...[key] }
    obj[orderId] = value

    if (key === 'cancelOrderConfirmOpen') setCancelOrderConfirmOpen(obj)
    if (key === 'cancelOrderModalOpen') setCancelOrderModalOpen(obj)
    if (key === 'refundOrderConfirmOpen') setRefundOrderConfirmOpen(obj)
    if (key === 'refundOrderModalOpen') setRefundOrderModalOpen(obj)
    if (key === 'orderNoteModalOpen') setOrderNoteModalOpen(obj)
  }

  const open = (key: string, orderId: string) => modalStateChange(key, orderId, true)
  const close = (key: string, orderId: string) => modalStateChange(key, orderId, false)

  const cancelOrder = (orderId: string) => {
    info('cancelling order', orderId)
    close('cancelOrderConfirmOpen', orderId)
    close('cancelOrderModalOpen', orderId)

    const storeCredit: wcCreateCreditType = {}
    if (sendStoreCredit[orderId]) {
      storeCredit.credit_amount = storeCreditAmount[orderId]
      storeCredit.credit_message = storeCreditMessage[orderId]
      storeCredit.credit_date_expires = dayjs().add(6, 'M').format('YYYY-MM-DD') // 6 months from now
      info('sending store credit', { ...storeCredit })
    }

    dispatch(
      orderUpdateActions.orderUpdateRequest(`cancel-${orderId}`, reg.id!, {
        orderId,
        order_status: 'cancelled',
        ...storeCredit,
      })
    )
  }

  const updateRefundAmount = (
    evt: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData,
    orderId: string
  ) => {
    setRefundAmount({ ...refundAmount, [orderId]: data.value }) // shallow copy is enough
  }

  const updateRefundReason = (
    evt: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData,
    orderId: string
  ) => {
    setRefundReason({ ...refundReason, [orderId]: data.value }) // shallow copy is enough
  }

  const refundOrder = (orderId: string) => {
    close('refundOrderConfirmOpen', orderId)
    close('refundOrderModalOpen', orderId)
    // TODO: if refund amount matches order total, should we have an option to restock all items?
    // (would this work for product bundle?)
    const refund = {
      amount: refundAmount[orderId],
      reason: refundReason[orderId],
    } as Exclude<RegistrantDataToUpdateType['refund'], undefined>
    const refundData = { orderId, refund } as RegistrantDataToUpdateType
    info('refunding order', orderId, reg.id, refundData)
    dispatch(orderUpdateActions.orderUpdateRequest(`refund-${orderId}`, reg.id!, refundData))
  }

  const updateOrderNoteContent = (
    evt: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData,
    orderId: string
  ) => {
    const newOrderNoteContent = { ...orderNoteContent, [orderId]: data.value } // shallow copy is enough
    setOrderNoteContent(newOrderNoteContent)
  }

  const updateOrderNotePublic = (
    evt: React.MouseEvent<HTMLInputElement, MouseEvent>,
    data: DropdownProps,
    orderId: string
  ) => {
    const newOrderNotePublic = { ...orderNotePublic, [orderId]: data.checked } // shallow copy is enough
    setOrderNotePublic(newOrderNotePublic)
  }

  const addOrderNote = (orderId: string) => {
    close('orderNoteModalOpen', orderId)
    const note = orderNoteContent[orderId]
    const customerNote = orderNotePublic[orderId]
    const orderData = {
      orderId,
      order_note: { note, customer_note: customerNote || false },
    }
    info('adding order note', orderId, reg.id, orderData)
    dispatch(orderUpdateActions.orderUpdateRequest(`addnote-${orderId}`, reg.id!, orderData))
  }

  const updateSendStoreCredit = (
    evt: React.MouseEvent<HTMLInputElement, MouseEvent>,
    data: CheckboxProps,
    orderId: string,
    orderTotal: number
  ) => {
    setSendStoreCredit({ ...sendStoreCredit, [orderId]: data.checked }) // shallow copy is enough

    if (data.checked) {
      // fill store credit amount if empty
      const newStoreCreditAmount = { ...storeCreditAmount }
      if (!objHasKey(newStoreCreditAmount, orderId)) {
        newStoreCreditAmount[orderId] = orderTotal.toString()
        setStoreCreditAmount(newStoreCreditAmount)
      }

      // fill store credit message if empty
      const newStoreCreditMessage = { ...storeCreditMessage }
      if (!objHasKey(newStoreCreditMessage, orderId)) {
        newStoreCreditMessage[orderId] = defaultMessage
        setStoreCreditMessage(newStoreCreditMessage)
      }
    }
  }

  const updateStoreCreditAmount = (
    evt: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData,
    orderId: string
  ) => {
    setStoreCreditAmount({ ...storeCreditAmount, [orderId]: data.value }) // shallow copy is enough
  }

  const updateStoreCreditMessage = (
    evt: React.ChangeEvent<HTMLTextAreaElement>,
    data: TextAreaProps,
    orderId: string
  ) => {
    setStoreCreditMessage({ ...storeCreditMessage, [orderId]: data.value?.toString() }) // shallow copy is enough
  }

  const discountDropdownChange = (
    evt: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => {
    // log('discountDropdownChange', data.value)
    const value = data.value as checkin.userRoleEnum
    const newUserRoles = value === 'default' ? [] : [value]
    onOrderTableChange({ values, userRoles: newUserRoles })
  }

  if (!reg) return null

  // log('... reg.items', reg.items)
  // log('... items', items)

  const { dropDownDefaultValue, dropDownOptions, dropDownKey } = getDropDownInfo(
    reg,
    name,
    orderIds,
    create,
    values,
    items,
    allItems
  )

  let discountDefaultValue = 'default'
  let discountDropdownDisabled = false
  if (create) {
    const userExists = regUserExists(reg, users)
    const predefinedUserRoles = getUserRoles(reg, users)
    discountDropdownDisabled = userExists && !!predefinedUserRoles.length

    if (userRoles.includes(userRoleEnum['24__under']))
      discountDefaultValue = userRoleEnum['24__under']
    if (userRoles.includes(userRoleEnum.senior)) discountDefaultValue = userRoleEnum.senior
    // log('discountDefaultValue', userRoles, discountDefaultValue)
  }

  return (
    <Table
      collapsing
      structured
      celled
      compact
      textAlign="center"
      className="registrant-edit-card-order-table"
    >
      <Table.Header>
        <Table.Row>
          {create ? null : (
            <Table.HeaderCell className="table-order-id">Order&nbsp;ID</Table.HeaderCell>
          )}
          <Table.HeaderCell className="table-order-items">Items</Table.HeaderCell>
          <Table.HeaderCell className="table-order-total">Total</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {Object.keys(reg.total || {})
          .filter(orderId => orderIds.indexOf(orderId) !== -1)
          .map(orderId => {
            const rows = [
              <Table.Row key={orderId}>
                {orderId === '999999' ? null : (
                  <Table.Cell>
                    <div className="cell-with-button">
                      <div className="cell-with-button-text">
                        {`#${orderId}`}
                        &nbsp;&nbsp;
                        <Refresh
                          componentType="circular button"
                          idx={`#${orderId}`}
                          popupProps={{ offset: [-17, 0], size: 'huge' }}
                          refreshEndpoints={[
                            {
                              endpoint: `refresh/orders/${orderId}`,
                              query: 'filterByItems=true',
                            },
                          ]}
                        />
                        &nbsp;&nbsp;
                        <Modal
                          open={orderNoteModalOpen[orderId]}
                          onClose={() => close('orderNoteModalOpen', orderId)}
                          trigger={
                            <Icon.Group onClick={() => open('orderNoteModalOpen', orderId)}>
                              <Icon name="sticky note outline" flipped="vertically" fitted />
                              <Icon corner name="add" />
                            </Icon.Group>
                          }
                        >
                          <Modal.Header>{`Add Order Note #${orderId}`}</Modal.Header>
                          <Modal.Content>
                            <Form>
                              <Form.Field>
                                <Checkbox
                                  label="Note to customer"
                                  checked={orderNotePublic[orderId]}
                                  onClick={(evt, data) => updateOrderNotePublic(evt, data, orderId)}
                                />
                              </Form.Field>
                              <Form.Field required>
                                <label htmlFor="order_note_content">Order Note</label>
                                <Input
                                  id="order_note_content"
                                  placeholder="write your note here"
                                  onChange={(evt, data) =>
                                    updateOrderNoteContent(evt, data, orderId)
                                  }
                                />
                              </Form.Field>
                            </Form>
                          </Modal.Content>
                          <Modal.Actions>
                            <Button
                              disabled={!orderNoteContent[orderId]}
                              color="green"
                              onClick={() => addOrderNote(orderId)}
                            >
                              Add Order Note
                            </Button>
                            <Button
                              color="blue"
                              icon="arrow right"
                              labelPosition="right"
                              content="Back"
                              onClick={() => close('orderNoteModalOpen', orderId)}
                            />
                          </Modal.Actions>
                        </Modal>
                      </div>
                      {!reg.cancellable[orderId] || reg.order_status[orderId].match(/trash/) ? (
                        <Button
                          size="huge"
                          compact
                          basic
                          color="red"
                          disabled
                          style={{ display: 'none' }}
                        >
                          Cancel
                        </Button>
                      ) : (
                        <Modal
                          size="fullscreen"
                          open={cancelOrderModalOpen[orderId]}
                          onClose={() => close('cancelOrderModalOpen', orderId)}
                          trigger={
                            <Button
                              size="huge"
                              compact
                              basic
                              color="red"
                              onClick={() => open('cancelOrderModalOpen', orderId)}
                              loading={
                                !!(orderUpdateStatus[`cancel-${orderId}`] || '').match(
                                  /ing/ /* FIXME /ing/ */
                                )
                              }
                            >
                              Cancel
                            </Button>
                          }
                        >
                          <Modal.Header>{`Cancel Order #${orderId}`}</Modal.Header>
                          <Modal.Content>
                            <p>
                              Are you sure you want to cancel this order? Make sure you proceed any
                              refunds first.
                            </p>
                            {!settings.uiRegistrants.supportStoreCredit ? null : (
                              <Form>
                                {reg.total[orderId] === 0 ? null : (
                                  <Form.Field>
                                    <Checkbox
                                      label="Send store credit to customer"
                                      checked={sendStoreCredit[orderId]}
                                      onClick={(evt, data) =>
                                        updateSendStoreCredit(
                                          evt,
                                          data,
                                          orderId,
                                          reg.total[orderId]
                                        )
                                      }
                                    />
                                  </Form.Field>
                                )}
                                <Transition.Group duration={200}>
                                  {sendStoreCredit[orderId] && (
                                    <div>
                                      <Form.Field required disabled={!sendStoreCredit[orderId]}>
                                        <label htmlFor="store_credit_amount">
                                          Specify the store credit amount
                                        </label>
                                        <Input
                                          labelPosition="left"
                                          placeholder="0.00"
                                          defaultValue={reg.total[orderId]}
                                          onChange={(evt, data) =>
                                            updateStoreCreditAmount(evt, data, orderId)
                                          }
                                        >
                                          <Label basic style={{ paddingTop: '1em' }}>
                                            $
                                          </Label>
                                          <input id="store_credit_amount" />
                                        </Input>
                                      </Form.Field>
                                      <Form.Field required disabled={!sendStoreCredit[orderId]}>
                                        <label htmlFor="store_credit_message">
                                          Store Credit Message
                                        </label>
                                        <TextArea
                                          id="store_credit_message"
                                          placeholder="write your message here"
                                          defaultValue={defaultMessage}
                                          onChange={(evt, data) =>
                                            updateStoreCreditMessage(evt, data, orderId)
                                          }
                                        />
                                      </Form.Field>
                                    </div>
                                  )}
                                </Transition.Group>
                              </Form>
                            )}
                          </Modal.Content>
                          <Modal.Actions>
                            <Button
                              disabled={
                                !sendStoreCredit[orderId]
                                  ? false
                                  : !storeCreditAmount[orderId] ||
                                    Number(storeCreditAmount[orderId]) <= 0 ||
                                    !storeCreditMessage[orderId]
                              }
                              color="red"
                              onClick={() => open('cancelOrderConfirmOpen', orderId)}
                            >
                              Cancel Order
                              {sendStoreCredit[orderId] && ' & Send Credit'}
                            </Button>
                            <Confirm
                              size="fullscreen"
                              content="Are you sure? Cancelling an order does not refund it."
                              open={cancelOrderConfirmOpen[orderId]}
                              onCancel={() => close('cancelOrderConfirmOpen', orderId)}
                              onConfirm={() => cancelOrder(orderId)}
                              confirmButton="Yes, Cancel"
                              cancelButton="No"
                            />
                            <Button
                              color="blue"
                              icon="arrow right"
                              labelPosition="right"
                              content="Back"
                              onClick={() => close('cancelOrderModalOpen', orderId)}
                            />
                          </Modal.Actions>
                        </Modal>
                      )}
                    </div>
                  </Table.Cell>
                )}
                <Table.Cell>
                  {/* added key to trigger render on reg items change */}
                  <Dropdown
                    key={dropDownKey[orderId]}
                    className="registrant-edit-card-dropdown"
                    placeholder="Choose items"
                    multiple
                    scrolling
                    selection
                    fluid
                    closeOnChange
                    search
                    options={dropDownOptions[orderId]}
                    value={dropDownDefaultValue[orderId]}
                    onChange={(evt, data) => onChange(data, orderId)}
                    renderLabel={label => renderLabel(label, orderId, defaultValues[orderId])}
                  />
                </Table.Cell>
                <Table.Cell>
                  <div className="cell-with-button">
                    <div className="cell-with-button-text">
                      <div className="order-total">{`$${reg.total[orderId]}`}</div>
                      {!create ? null : (
                        <Input
                          className="order-discount"
                          label="Discount"
                          labelPosition="left"
                          error={reg.discount_total[orderId] > reg.total[orderId]}
                          value={orderDiscountInput[orderId] ?? ''}
                          onChange={onChangeOrderDiscountInput(orderId)}
                          input={<input size={3} />}
                          size="small"
                          // type="number"
                          action={
                            <Dropdown
                              key={discountDefaultValue}
                              button
                              basic
                              floating
                              options={discountOptions}
                              defaultValue={discountDefaultValue}
                              disabled={discountDropdownDisabled}
                              onChange={discountDropdownChange}
                            />
                          }
                        />
                      )}
                    </div>
                    {!reg.refundable[orderId] || reg.refundable[orderId].toString() === '1' ? (
                      ''
                    ) : (
                      <Button size="huge" compact basic disabled>
                        {
                          reg.refundable[orderId]
                            .toString()
                            .replace(/\s/g, '\u00a0') /* keep blank spaces insecable */
                        }
                      </Button>
                    )}
                    {!reg.refundable[orderId] || reg.refundable[orderId].toString() !== '1' ? (
                      ''
                    ) : (
                      <Modal
                        size="fullscreen"
                        open={refundOrderModalOpen[orderId]}
                        onClose={() => close('refundOrderModalOpen', orderId)}
                        trigger={
                          <Button
                            size="huge"
                            compact
                            basic
                            color="green"
                            onClick={() => open('refundOrderModalOpen', orderId)}
                            loading={
                              !!(orderUpdateStatus[`refund-${orderId}`] || '').match(
                                /ing/ /* FIXME /ing/ */
                              )
                            }
                          >
                            Refund
                          </Button>
                        }
                      >
                        <Modal.Header>{`Refund Order #${orderId}`}</Modal.Header>
                        <Modal.Content>
                          <Form>
                            <Form.Field required>
                              <label htmlFor="refund_amount">Specify the refund amount</label>
                              <Input
                                labelPosition="left"
                                placeholder="0.00"
                                onChange={(evt, data) => updateRefundAmount(evt, data, orderId)}
                              >
                                <Label basic style={{ paddingTop: '1em' }}>
                                  $
                                </Label>
                                <input id="refund_amount" />
                              </Input>
                            </Form.Field>
                            <Form.Field required>
                              <label htmlFor="refund_reason">
                                Specify the reason for the refund
                              </label>
                              <Input
                                id="refund_reason"
                                placeholder="reason for the refund"
                                onChange={(evt, data) => updateRefundReason(evt, data, orderId)}
                              />
                            </Form.Field>
                          </Form>
                        </Modal.Content>
                        <Modal.Actions>
                          <Button
                            disabled={
                              !refundAmount[orderId] ||
                              Number(refundAmount[orderId]) <= 0 ||
                              !refundReason[orderId]
                            }
                            color="green"
                            onClick={() => open('refundOrderConfirmOpen', orderId)}
                          >
                            Refund Order
                          </Button>
                          <Confirm
                            size="fullscreen"
                            content="Are you sure you want to refund this order?"
                            open={refundOrderConfirmOpen[orderId]}
                            onCancel={() => close('refundOrderConfirmOpen', orderId)}
                            onConfirm={() => refundOrder(orderId)}
                            confirmButton="Yes, Refund"
                            cancelButton="No"
                          />
                          <Button
                            color="blue"
                            icon="arrow right"
                            labelPosition="right"
                            content="Back"
                            onClick={() => close('refundOrderModalOpen', orderId)}
                          />
                        </Modal.Actions>
                      </Modal>
                    )}
                  </div>
                </Table.Cell>
              </Table.Row>,
            ]

            if (reg.refunds[orderId]) {
              rows.push(
                <Table.Row key={JSON.stringify(reg.refunds[orderId])}>
                  <Table.Cell colSpan="3" className="registrant-edit-card-order-table-refunds">
                    <OrderRefunds
                      className="order-refunds"
                      reg={reg}
                      name={name}
                      orderId={orderId}
                      openAccordion
                    />
                  </Table.Cell>
                </Table.Row>
              )
            }

            if (reg.order_notes[orderId]) {
              rows.push(
                <Table.Row key={JSON.stringify(reg.order_notes[orderId])}>
                  <Table.Cell colSpan="3" className="registrant-edit-card-order-table-notes">
                    <OrderNotes
                      className="order-notes"
                      reg={reg}
                      name={name}
                      orderId={orderId}
                      openAccordion
                    />
                  </Table.Cell>
                </Table.Row>
              )
            }

            if (reg.customer_note[orderId]) {
              rows.push(
                <Table.Row key={reg.customer_note[orderId]}>
                  <Table.Cell colSpan="3">
                    Note:&nbsp;
                    {reg.customer_note[orderId]}
                  </Table.Cell>
                </Table.Row>
              )
            }
            return rows
          })}
      </Table.Body>
    </Table>
  )
}

export default RegistrantEditCardOrderTable
