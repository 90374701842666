import React, { useCallback, useState } from 'react'
import { useLocation } from 'react-router'
import debug from 'debug'
import RegistrantsList from './RegistrantsList'
import RegistrantEditCard from './RegistrantEditCard'
import BottomMenu, { UpdateBottomMenuType } from '../bottommenu'

import './style.css'

debug.enable('registrants/index:*')
// const log = debug('registrants/index:log')
// const info = debug('registrants/index:info')
// const error = debug('registrants/index:error')

type Props = {
  mode: 'view' | 'edit'
}

const Registrants = ({ mode }: Props) => {
  const [itemTitle, setItemTitle] = useState('')
  const [buttonTitle, setButtonTitle] = useState('')
  const [buttonLink, setButtonLink] = useState('')

  const updateBottomMenu = useCallback(
    ({
      itemTitle: newItemTitle,
      buttonTitle: newButtonTitle,
      buttonLink: newButtonLink,
    }: Parameters<UpdateBottomMenuType>[0]) => {
      setItemTitle(newItemTitle)
      setButtonTitle(newButtonTitle)
      setButtonLink(newButtonLink)
    },
    [setItemTitle, setButtonTitle, setButtonLink]
  )

  const location = useLocation()
  const create = location.pathname === '/register'

  // use key to trigger re-render when we switch between create and edit
  const key = create ? 'create' : 'edit'

  return (
    <div>
      {mode === 'view' ? (
        <RegistrantsList updateBottomMenu={updateBottomMenu} />
      ) : (
        <RegistrantEditCard create={create} key={key} updateBottomMenu={updateBottomMenu} />
      )}
      <BottomMenu
        itemTitle={itemTitle}
        buttonTitle={buttonTitle}
        buttonLink={buttonLink}
        buttonClassName="another-class-button"
      />
    </div>
  )
}

export default Registrants
